<template>
  <div class="index-container" style="margin-top: 1rem;min-height: 100vh">
      <div style="padding: 1rem;padding-top: 0;">

        <div class="games-container background-panel">
          <div style="display: flex;align-items: center;flex-direction: column;">
            <div style="font-size: 18px;cursor: pointer;white-space: nowrap;">
              <h3>SLOT - สแกนสล็อต </h3>
            </div>
          </div>
          <div class="game-list" style="padding: 1rem;padding-top: 0;height: 100%">
            <button class="game-card highlight-primary" @click="nav_goto(`/game/${url}`)"  v-for="{ icon, url, status }, i in games.filter(({ type }) => type == 'Slot')" :key="i.key" :disabled="!status">
              <img style="width: 120px; height: 48.71px;object-fit: cover;" :src="icon" />
              <div class="overlay" v-if="!status" :src="icon" >
                <!-- <span>เร็วๆนี้</span> -->
              </div>
            </button>
          </div>
        </div>

        <div class="games-container background-panel">
          <div style="display: flex;align-items: center;flex-direction: column;">
            <div style="font-size: 18px;cursor: pointer;white-space: nowrap;">
              <h3>BACARAT - สูตรบาคาร่า </h3>
            </div>
          </div>

          <div class="game-list" style="padding: 1rem;padding-top: 0;height: 100%">
            <button class="game-card highlight-primary" @click="nav_goto(`/game/${url}`)"  v-for="{ icon, url, status }, i in games.filter(({ type }) => type == 'Live')" :key="i.key" :disabled="!status">
              <img style="width: 120px; height: 48.71px;object-fit: cover;" :src="icon" />
              <div class="overlay" v-if="!status" :src="icon" >
                <!-- <span>เร็วๆนี้</span> -->
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2';

export default {
  name: "index",
  data() {
    return {
      close_game: false,
      games: [
        { icon: require('@/assets/games/gm-icon.svg'), url: 'GM', type: 'Slot' , status: false},
        { icon: require('@/assets/games/joker-icon.svg'), url: 'joker', type: 'Slot' , status: true},
        { icon: require('@/assets/games/pg-icon.svg'), url: 'pg', type: 'Slot' , status: true},
        { icon: require('@/assets/games/spi-icon.svg'), url: 'spi', type: 'Slot' , status: false},
        { icon: require('@/assets/games/vivo-icon.svg'), url: 'vivo', type: 'Slot' , status: false},

        { icon: require('@/assets/games/sa-icon.svg'), url: 'sa', type: 'Live', status: false},
        { icon: require('@/assets/games/sg-icon.png'), url: 'sg', type: 'Live', status: true},
        { icon: require('@/assets/games/wm-icon.svg'), url: 'wm', type: 'Live', status: false},
      ]
    };
  },
  methods: {
    nav_goto(path) {
      try {
        this.$router.push(path);
      }
      catch (err) {
        console.log(err);
      }
    },
    check_user() {
      if (!localStorage.getItem("user")) {
        this.$router.push("/register");
      }
    }
  },
  mounted() {
    if (this.close_game) {
      Swal.fire({
        html: `เว็ปเราจะเปิดให้บริการเร็วๆนะค่ะคุณลูกค้า <br>
          วันที่เว็ปเปิดแล้ว เดี๋ยวแอดมินจะแจ้งลูกค้าให้รับทราบนะค่ะ <br>
          พร้อมโปรโมชั่นพิเศษฉลองเปิดใหม่และมีกิจกรรมแจกเครดิสฟรีไม่อัดตลอดทั้งอาทิตย์ <br>
          อย่าพลาดกันค่ะคุณลูกค้า <br>
          อดใจรอสักครู่นะคุณลูกค้าที่น่ารัก <br>
          ขอบคุณมากๆนะค่ะคุณลูกค้า &#128591 `
      })
      this.nav_goto('/profile')
    }
      this.check_user();
  },
}
</script>

<style lang="less" scoped>
  .game-card {
    height: fit-content;
    position: relative
  }
  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fde383;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #0e17209b;
    span {
      position: absolute;
      bottom: 11px;
      width: 100%;
      border: 1px solid #fde383;
      background-color: #0e1720;
    }
  }
  .games-container {
    margin-top: 2rem;
  }
  .background-panel {
    background-color: #0e1720;
  }
  .game-list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1 1 auto;
    button {
      margin: 0.895rem;
      padding: 0;
      background-color: #0f1720
    }
    img {
      cursor: pointer;
    }
  }
  .index-container::-webkit-scrollbar {
    display: none
  }
</style>
